import Grid, { GridProps } from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {
  createRef,
  FunctionComponent,
  KeyboardEvent,
  RefObject,
  useCallback,
  useState,
} from 'react';

interface OtpInputProps extends GridProps {
  onSubmitOtp: (otp: string) => void;
  onChangeOtp?: (otp: string) => void;
}

const OtpInput: FunctionComponent<OtpInputProps> = (props) => {
  const { onSubmitOtp, onChangeOtp, ...gridProps } = props;
  const inputRefs: RefObject<HTMLInputElement>[] = [...Array(6)].map(() => createRef());
  const [otpVal, setOtpVal] = useState([...Array(6)].fill(''));

  const onChange = useCallback(
    (index: number, value: string) => {
      const formattedVal = value.replace(/\D+/g, '');
      const newOtp = otpVal.slice();
      newOtp[index] = formattedVal;
      if ((index === 5 && newOtp[index].length <= 1) || index !== 5) {
        setOtpVal(newOtp);
        if (index !== inputRefs.length - 1 && formattedVal.length > 0) {
          inputRefs[index + 1].current?.focus();
        }

        if (onChangeOtp) onChangeOtp(newOtp.filter((v) => !!v).join(''));

        if (index === inputRefs.length - 1) {
          onSubmitOtp(newOtp.join(''));
        }
      }
    },
    [inputRefs, onChangeOtp, onSubmitOtp, otpVal]
  );

  const onDeletePressed = useCallback(
    (event: KeyboardEvent<HTMLDivElement>, index: number): void => {
      if (event.key === 'Backspace') {
        if (otpVal[index].length === 0 && index !== 0) {
          inputRefs[index - 1].current?.focus();
        } else {
          const newOtp = otpVal.slice();
          newOtp[index] = '';
          if (onChangeOtp) onChangeOtp(newOtp.filter((v) => !!v).join(''));
          setOtpVal(newOtp);
          event.preventDefault();
        }
      }
    },
    [inputRefs, otpVal, onChangeOtp]
  );

  return (
    <Grid container justifyContent="space-between" {...gridProps}>
      {otpVal.map((val, idx) => (
        <Grid item key={idx} sx={{ width: '40px' }}>
          <TextField
            fullWidth
            inputRef={inputRefs[idx]}
            size="small"
            value={val}
            onChange={(e) => onChange(idx, e.target.value)}
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center' },
              type: 'number',
            }}
            autoFocus={idx === 0}
            onKeyDown={(event) => onDeletePressed(event, idx)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default OtpInput;
