import { post } from '@utils/fetch';

interface DataVerifyPhone {
  phone: string;
  countryCode: string;
}

interface DataVerifyPhone2 {
  phone: string;
  countryCode: string;
  type: 'user' | 'chef' | 'venue';
}

interface ResponseVerifyPhone {
  refCode: string;
}

interface ResponseVerifyTransaction {
  transactionID: number;
}

interface DataVerifyOtpPhone {
  otp: string;
  ref: string;
}

export function verify_otp_transaction(
  token: string,
  ref: string,
  transactionID: string,
  otp: string
) {
  return post<ResponseVerifyTransaction[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/verify-otp-transaction`,
    {
      otp,
      ref,
      transactionID,
    },
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export function verify_phone_transaction(token: string, phone: string, transactionID: string) {
  return post<ResponseVerifyPhone[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/verify-phone-transaction`,
    {
      phone,
      transactionID,
    },
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export function verify_otp_venue(token: string, ref: string, otp: string) {
  return post<ResponseVerifyTransaction[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/verify-otp-venue-owner`,
    {
      otp: otp,
      ref: ref,
    },
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export function verify_phone_venue(token: string, phone: string, countryCode: string) {
  return post<ResponseVerifyPhone[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/verify-phone-venue-owner`,
    {
      phone: phone,
      countryCode: countryCode,
    },
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export function verify_phone(token: string, data: DataVerifyPhone, type?: string) {
  const path = type !== 'chef' ? 'verify-phone' : 'verify-phone-chef';
  return post<ResponseVerifyPhone[]>(`${process.env.NEXT_PUBLIC_API_URL}/${path}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function verify_otp(token: string, data: DataVerifyOtpPhone, type?: string) {
  const path = type !== 'chef' ? 'verify-otp' : 'verify-otp-chef';
  return post(`${process.env.NEXT_PUBLIC_API_URL}/${path}`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function save_phone(data: DataVerifyPhone2, token: string) {
  const path = 'save-phone-verify';
  return post(`${process.env.NEXT_PUBLIC_API_URL}/${path}`, data, {
    Authorization: `Bearer ${token}`,
  });
}
